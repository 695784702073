import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import api from '../services/api';
import { OpenData, WebContextType, WebData } from '../types/context';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const WebSettingsContext = createContext<WebContextType | undefined>(undefined);

export const WebSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [webData, setWebData] = useState<WebData | null>(null);
  const [openData, setOpenData] = useState<OpenData | null>(null);
  const location = useLocation();
  // getting todays day (monday tuesday etc)
  const today = new Date().getDay();
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const [loading, setLoading] = useState<boolean>(true);
  const handleError = (error: any, defaultMessage: string): string => {
    const errorMessage = error.response?.data?.message || defaultMessage;
    return errorMessage;
  };

  React.useEffect(() => {
    fetchWebData();
  }, [location.pathname]);
  const fetchWebData = async () => {
    try {
      const response = await api.get('/web/settings');
      const worktimeresponse = await api.post('/web/worktimes', { day: days[today] });

      setWebData(response.data.data);

      const fetchedOpenData = worktimeresponse.data.data as OpenData;

      // Format the times to remove seconds
      const formattedOpenData = {
        ...fetchedOpenData,
        open_time: fetchedOpenData.open_time.split(':').slice(0, 2).join(':'),
        close_time: fetchedOpenData.close_time.split(':').slice(0, 2).join(':'),
        open: isOpen(fetchedOpenData)
      };

      setOpenData(formattedOpenData);
    } catch (error) {
      console.error('Failed to fetch user data', error);
      handleError(error, 'Failed to fetch user data');
    } finally {
      setLoading(false);
    }
  };
  function isOpen(openData: OpenData): boolean {
    const currentFinlandTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Helsinki' });
    const currentTime = new Date(currentFinlandTime);
    // for demo its istanbul
    //const currentIstanbulTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Istanbul' });
    //const currentTime = new Date(currentIstanbulTime);

    // Format open and close times to remove seconds
    const openTimeFormatted = openData.open_time.split(':').slice(0, 2).join(':');
    const closeTimeFormatted = openData.close_time.split(':').slice(0, 2).join(':');

    const [openHour, openMinute] = openTimeFormatted.split(':').map(Number);
    const [closeHour, closeMinute] = closeTimeFormatted.split(':').map(Number);

    const openTime = new Date(currentTime);
    openTime.setHours(openHour, openMinute, 0); // Set seconds to 0

    const closeTime = new Date(currentTime);
    closeTime.setHours(closeHour, closeMinute, 0); // Set seconds to 0

    return currentTime >= openTime && currentTime <= closeTime;
  }
  const { t } = useTranslation();
  const getTitle = () => {
    switch (location.pathname) {
      case '/':
        return `${webData?.restaurant_name || process.env.REACT_APP_SITE_TITLE} - ${t('Home')}`;
      case '/contact':
        return `${webData?.restaurant_name || process.env.REACT_APP_SITE_TITLE} - ${t('Contact')}`;
      case '/payment':
        return `${webData?.restaurant_name || process.env.REACT_APP_SITE_TITLE} - ${t('Payment')}`;
      case '/order-history':
        return `${webData?.restaurant_name || process.env.REACT_APP_SITE_TITLE} - ${t(
          'Order History'
        )}`;
      case '/settings':
        return `${webData?.restaurant_name || process.env.REACT_APP_SITE_TITLE} - ${t('Settings')}`;
      case '/login':
        return `${webData?.restaurant_name || process.env.REACT_APP_SITE_TITLE} - ${t('Login')}`;
      case '/register':
        return `${webData?.restaurant_name || process.env.REACT_APP_SITE_TITLE} - ${t('Register')}`;
      default:
        return webData?.restaurant_name || process.env.REACT_APP_SITE_TITLE;
    }
  };
  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
        <meta
          name="description"
          content={t(
            'Discover the authentic taste of {{restaurant}} in Riihimäki, Finland. Enjoy delicious kebabs and pizza made with fresh ingredients for a satisfying meal.',
            { restaurant: webData?.restaurant_name || process.env.REACT_APP_SITE_TITLE }
          )}
        />
      </Helmet>
      <WebSettingsContext.Provider value={{ webData, openData, loading, fetchWebData }}>
        {children}
      </WebSettingsContext.Provider>
    </>
  );
};

export const useWebSettings = () => {
  const context = useContext(WebSettingsContext);
  if (!context) {
    throw new Error('useWebSettings must be used within a WebSettingsProvider');
  }
  return context;
};
