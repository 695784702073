import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../common/Button';
import api from '../../../../services/api';
import Modal from '../../../common/Modal';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../common/Spinner';
import DeleteWarning from '../common/DeleteWarning';

interface Category {
  id: string;
  category_name: string;
  category_icon: string;
  discount_percent: number;
  description: string;
  created_at: string;
}

const Categories: React.FC = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [newCategory, setNewCategory] = useState({
    category_name: '',
    category_icon: '',
    discount_percent: 0,
    description: ''
  });
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [selectedCategoryId, setselectedCategoryId] = useState<string | null>(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await api.get('/products/get-all-categories');
      setCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const updatedValue = name === 'discount_percent' ? parseFloat(value) : value;
    if (editingCategory) {
      setEditingCategory({ ...editingCategory, [name]: updatedValue });
    } else {
      setNewCategory({ ...newCategory, [name]: updatedValue });
    }
  };

  const handleWarning = (userId: string) => {
    setselectedCategoryId(userId);
    setWarningModal(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (editingCategory) {
        const { id, created_at, ...updatedCategory } = editingCategory;
        await api.put(`/admin/edit-category/${id}`, updatedCategory);
      } else {
        await api.post('/admin/add-category', newCategory);
      }
      fetchCategories();
      setNewCategory({
        category_name: '',
        category_icon: '',
        discount_percent: 0,
        description: ''
      });
      setEditingCategory(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleEdit = (category: Category) => {
    setEditingCategory(category);
    setIsModalOpen(true);
  };

  const handleDeleteUser = async () => {
    if (selectedCategoryId) {
      try {
        await api.delete(`/admin/delete-category/${selectedCategoryId}`);
        setCategories(categories.filter(category => category.id !== selectedCategoryId));
        setWarningModal(false);
        setselectedCategoryId(null);
      } catch (err) {
        console.error('Error deleting user:', err);
        // Optionally, you can set an error state here to show an error message to the user
      }
    }
  };

  // const handleDelete = async (id: string) => {
  //   if (window.confirm('Are you sure you want to delete this category?')) {
  //     try {
  //       await api.delete(`/admin/delete-category/${id}`);
  //       fetchCategories();
  //     } catch (error) {
  //       console.error('Error deleting category:', error);
  //     }
  //   }
  // };

  return (
    <div className="w-full text-stone-900 dark:text-zinc-300">
      <div>
        <h1 className="flex justify-start items-center pt-4 font-bold text-[40px] text-stone-900 dark:text-orange-600">
          {t('CategoriesAdmin.Title')}
        </h1>
        <p>{t('CategoriesAdmin.DescriptionCategory')}</p>
      </div>
      <div className="mt-8">
        <Button variant="tertiary" onClick={() => setIsModalOpen(true)}>
          {t('CategoriesAdmin.AddNewCategory')}
        </Button>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="mt-8">
          <table className="w-full table-fixed border-collapse">
            <thead className="bg-slate-300 dark:bg-stone-800">
              <tr>
                <th className="w-1/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('CategoriesAdmin.ID')}
                </th>
                <th className="w-2/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('CategoriesAdmin.Name')}
                </th>
                <th className="w-3/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('CategoriesAdmin.IconURL')}
                </th>
                <th className="w-2/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('CategoriesAdmin.DiscountPercent')}
                </th>
                <th className="w-2/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('CategoriesAdmin.Actions')}
                </th>
              </tr>
            </thead>
            <tbody className="text-stone-900 dark:text-zinc-300 bg-slate-200 dark:bg-stone-700">
              {categories.map(category => (
                <tr key={category.id} className="hover:bg-slate-300 dark:hover:bg-stone-600">
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    {category.id}
                  </td>
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    {category.category_name}
                  </td>
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400 overflow-hidden">
                    {category.category_icon}
                  </td>
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    {category.discount_percent}%
                  </td>
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    <div className="flex flex-row w-full gap-4">
                      <Button
                        className="w-1/2"
                        variant="tertiary"
                        onClick={() => handleEdit(category)}
                        data-tooltip-content={t('Edit Category')}
                        data-tooltip-id="tooltip"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <Button
                        variant="cancel"
                        className="w-1/2"
                        onClick={() => handleWarning(category.id)}
                        data-tooltip-content={t('Delete Category')}
                        data-tooltip-id="tooltip"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                      <DeleteWarning
                        modalOpen={warningModal}
                        setModalOpen={setWarningModal}
                        onConfirm={handleDeleteUser}
                        description={t('Listcategories.ConfirmDelete')}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        title={
          editingCategory ? t('CategoriesAdmin.EditCategory') : t('CategoriesAdmin.AddCategory')
        }
        onClose={() => {
          setIsModalOpen(false);
          setEditingCategory(null);
        }}
      >
        <form onSubmit={handleSubmit} className="space-y-4 w-72 h-auto">
          <div>
            <label htmlFor="category_name" className="block text-lg font-medium mb-1">
              {t('CategoriesAdmin.CategoryName')}
            </label>
            <input
              type="text"
              id="category_name"
              name="category_name"
              value={editingCategory ? editingCategory.category_name : newCategory.category_name}
              onChange={handleInputChange}
              className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full"
              required
            />
          </div>
          <div>
            <label htmlFor="category_icon" className="block text-lg font-medium mb-1">
              {t('CategoriesAdmin.CategoryIconURL')}
            </label>
            <input
              type="text"
              id="category_icon"
              name="category_icon"
              placeholder="url"
              value={editingCategory ? editingCategory.category_icon : newCategory.category_icon}
              onChange={handleInputChange}
              className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full"
              required
            />
          </div>
          <div>
            <label htmlFor="discount_percent" className="block text-lg font-medium mb-1">
              {t('CategoriesAdmin.DiscountPercent')}
            </label>
            <input
              type="number"
              id="discount_percent"
              name="discount_percent"
              value={
                editingCategory ? editingCategory.discount_percent : newCategory.discount_percent
              }
              onChange={handleInputChange}
              className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full"
              required
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-lg font-medium mb-1">
              {t('CategoriesAdmin.Description')}
            </label>
            <textarea
              id="description"
              name="description"
              value={editingCategory ? editingCategory.description : newCategory.description}
              onChange={handleInputChange}
              className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full"
              rows={4}
            />
          </div>
          <div className="justify-end flex">
            <Button type="submit" variant="tertiary">
              {editingCategory
                ? t('CategoriesAdmin.UpdateCategory')
                : t('CategoriesAdmin.AddCategory')}
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Categories;
