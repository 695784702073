import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import api from '../../services/api';
import Spinner from '../common/Spinner';
import { useTranslation } from 'react-i18next';

interface PrivacyPolicyData {
  id: number;
  document_type: string;
  version: string;
  language_code: string;
  title: string;
  content: string;
  is_active: number;
  created_at: string;
  updated_at: string;
}

const PrivacyPolicy: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [policyData, setPolicyData] = useState<PrivacyPolicyData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await api.get('/web/privacy-policy');
        if (response.data.success) {
          setPolicyData(response.data.data);
        } else {
          setError(t('Failed to load privacy policy'));
        }
      } catch (err) {
        setError(t('Error loading privacy policy'));
        console.error('Error fetching privacy policy:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPrivacyPolicy();
  }, [t]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-zinc-50 dark:bg-stone-900">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-zinc-50 dark:bg-stone-900">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <motion.div
      className="min-h-screen bg-zinc-50 dark:bg-stone-900"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {policyData && (
        <div className="max-w-4xl mx-auto px-4 py-12">
          <div className="prose prose-stone dark:prose-invert max-w-none text-stone-900 dark:text-zinc-100">
            <div
              className="[&_.policy-section]:mb-12
                         [&_h1]:text-4xl [&_h1]:font-bold [&_h1]:mb-4 [&_h1]:text-stone-900 dark:[&_h1]:text-zinc-100
                         [&_h3]:text-2xl [&_h3]:font-semibold [&_h3]:mb-6 [&_h3]:text-stone-900 dark:[&_h3]:text-zinc-100
                         [&_p]:mb-4 [&_p]:leading-relaxed [&_p]:text-base [&_p]:text-stone-800 dark:[&_p]:text-zinc-200
                         [&_ul]:list-disc [&_ul]:pl-6 [&_ul]:mb-4 [&_ul>li]:mb-2 [&_ul>li]:text-stone-800 dark:[&_ul>li]:text-zinc-200
                         [&_a]:text-orange-600 dark:[&_a]:text-orange-400 [&_a]:underline hover:[&_a]:text-orange-700 dark:hover:[&_a]:text-orange-300
                         [&_strong]:text-stone-900 dark:[&_strong]:text-zinc-100
                         [&_.text-gray-600]:text-stone-600 dark:[&_.text-gray-600]:text-zinc-400"
              dangerouslySetInnerHTML={{ __html: policyData.content }}
            />
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default PrivacyPolicy;
