import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useNotification } from '../../../context/NotificationContext';
import { NotificationType, Position } from '../../../types/enums/notificationEnums';
import Button from '../../common/Button';
import api from '../../../services/api';
import GoBackButton from '../../common/GoBackButton';
import Spinner from '../../common/Spinner';
import LabeledInput from '../../common/LabeledInput';

const DeleteAccount: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { showNotification } = useNotification();
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Function to handle the initial delete request
  const handleDeleteRequest = () => {
    setShowConfirmation(true);
  };

  // Function to handle the final account deletion
  const handleDeleteAccount = async () => {
    if (confirmText !== 'DELETE') {
      showNotification(
        t('Please type DELETE to confirm account deletion'),
        NotificationType.Warning,
        Position.TopRight
      );
      return;
    }

    setIsDeleting(true);
    try {
      await api.delete(`/user/delete-user/${user?.id}`);
      showNotification(
        t('Your account has been successfully deleted'),
        NotificationType.Success,
        Position.TopRight
      );
      logout();
      navigate('/');
    } catch (error) {
      showNotification(
        t('There was an error deleting your account'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto px-4 md:px-0">
      <div className="w-full p-8 bg-slate-200 dark:bg-stone-800 rounded-md">
        <h2 className="text-2xl font-bold mb-8 text-stone-900 dark:text-orange-600">
          {t('Delete Account')}
        </h2>

        <div className="space-y-8">
          <div className="bg-red-50 dark:bg-red-900/30 p-6 rounded-lg border border-red-200 dark:border-red-800">
            <p className="text-red-700 dark:text-red-200 font-medium text-lg mb-4">
              {t('Warning: This action cannot be undone')}
            </p>
            <p className="text-red-600 dark:text-red-300 mb-3 text-base">{t('Deleting your account will:')}</p>
            <ul className="list-disc ml-6 space-y-2 text-red-600 dark:text-red-300 text-base">
              <li>{t('Permanently delete your account information')}</li>
              <li>{t('Remove all your saved addresses')}</li>
              <li>{t('Delete your order history')}</li>
              <li>{t('Log you out immediately')}</li>
            </ul>
          </div>

          {!showConfirmation ? (
            <Button
              variant="cancel"
              className="w-full py-3 text-base"
              onClick={handleDeleteRequest}
              disabled={isDeleting}
            >
              {t('I want to delete my account')}
            </Button>
          ) : (
            <div className="space-y-6 bg-slate-100 dark:bg-stone-700/50 p-6 rounded-lg">
              <p className="text-stone-700 dark:text-zinc-200 text-base">
                {t('Please type')} <span className="font-bold text-red-600 dark:text-red-400">{t('DELETE')}</span>{' '}
                {t('to confirm account deletion')}:
              </p>
              <LabeledInput
                type="text"
                value={confirmText}
                onChange={(e) => setConfirmText(e.target.value)}
                className="w-full p-3 text-lg border rounded-lg bg-white dark:bg-stone-800 text-stone-900 dark:text-zinc-300 border-red-200 dark:border-red-900 focus:ring-2 focus:ring-red-500 focus:border-transparent"
                placeholder={t('Type DELETE here')}
              />
              <div className="flex gap-4 pt-2">
                <Button
                  variant="tertiary"
                  className="w-1/2 text-lg"
                  onClick={handleDeleteAccount}
                  disabled={isDeleting || confirmText !== t('DELETE')}
                >
                  {isDeleting ? <Spinner size="sm" /> : t('Confirm Deletion')}
                </Button>
                <Button
                  variant="cancel"
                  className="w-1/2 text-base"
                  onClick={() => setShowConfirmation(false)}
                  disabled={isDeleting}
                >
                  {t('Cancel')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <GoBackButton
        to="/settings"
        className="block md:hidden mt-6 dark:text-stone-200 text-stone-800"
      >
        <span className="text-[17px]">{t('Turn back to settings')}</span>
      </GoBackButton>
    </div>
  );
};

export default DeleteAccount;
