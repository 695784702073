import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../common/Button';
import Modal from '../../../common/Modal';
import DeleteWarning from '../common/DeleteWarning';
import api from '../../../../services/api';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../common/Spinner';

interface Product {
  id: number;
  active: number;
  category_id: number;
  product_name: string;
  description: string;
  discount_percent: string;
  price: string;
  image_url: string;
  created_at: string;
}

interface Category {
  id: number;
  category_name: string;
}

interface Ingredient {
  id: number;
  ingredient_name: string;
}

interface Size {
  size_name: string;
  size_price: string;
}

const Products: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [displayedProducts, setDisplayedProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const productsPerPage = 10;
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [selectedIngredients, setSelectedIngredients] = useState<number[]>([]);
  const [sizes, setSizes] = useState<Size[]>([{ size_name: '', size_price: '' }]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchProducts();
    fetchCategories();
    fetchIngredients();
  }, []);

  useEffect(() => {
    const filtered = products.filter(
      product =>
        product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setDisplayedProducts(filtered.slice(0, page * productsPerPage));
  }, [products, searchTerm, page]);

  const fetchProducts = async () => {
    try {
      const response = await api.get('/products/get-all-products');
      setProducts(response.data.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError('Failed to fetch products. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await api.get('/products/get-all-categories');
      setCategories(response.data.data);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const fetchIngredients = async () => {
    try {
      const response = await api.get('/admin/get-all-ingredients');
      setIngredients(response.data.data);
    } catch (err) {
      console.error('Error fetching ingredients:', err);
    }
  };

  const handleEdit = (product: Product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleDelete = (product: Product) => {
    setSelectedProduct(product);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (selectedProduct) {
      try {
        await api.delete(`/admin/delete-product/${selectedProduct.id}`);
        setProducts(products.filter(p => p.id !== selectedProduct.id));
        setIsDeleteModalOpen(false);
      } catch (err) {
        console.error('Error deleting product:', err);
      }
    }
  };

  const handleIngredientChange = (ingredientId: number) => {
    setSelectedIngredients(prev =>
      prev.includes(ingredientId) ? prev.filter(id => id !== ingredientId) : [...prev, ingredientId]
    );
  };

  const handleSizeChange = (index: number, field: keyof Size, value: string) => {
    const newSizes = [...sizes];
    newSizes[index][field] = value;
    setSizes(newSizes);
  };

  const addSize = () => {
    setSizes([...sizes, { size_name: '', size_price: '' }]);
  };

  const removeSize = (index: number) => {
    const newSizes = sizes.filter((_, i) => i !== index);
    setSizes(newSizes);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const productData: Record<string, any> = {};

    // Convert FormData to a plain object
    formData.forEach((value, key) => {
      if (key === 'active') {
        productData[key] = value === 'on' ? 1 : 0;
      } else {
        productData[key] = value;
      }
    });

    // Add selected ingredients and sizes to productData
    productData.ingredient_ids = selectedIngredients;
    productData.sizes = sizes.filter(size => size.size_name && size.size_price);

    const sendFormData = new FormData();
    Object.entries(productData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (typeof item === 'object') {
            Object.entries(item).forEach(([subKey, subValue]) => {
              sendFormData.append(`${key}[${index}][${subKey}]`, subValue as string);
            });
          } else {
            sendFormData.append(`${key}[]`, item as string);
          }
        });
      } else {
        sendFormData.append(key, value as string);
      }
    });

    try {
      if (selectedProduct) {
        // Use the correct endpoint for updating a product
        await api.post(`/admin/edit-product/${selectedProduct.id}`, sendFormData);
      } else {
        await api.post('/admin/add-product', sendFormData);
      }
      fetchProducts();
      setIsModalOpen(false);
      setSelectedProduct(null);
      setSelectedIngredients([]);
      setSizes([{ size_name: '', size_price: '' }]);
    } catch (err) {
      console.error('Error saving product:', err);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      setPage(1);
    }, 300),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="w-full text-stone-900 dark:text-zinc-300 pb-4">
      <div>
        <h1 className="flex justify-start items-center pt-4 font-bold text-[40px] text-stone-900 dark:text-orange-600">
          {t('ProductsAdmin.Title')}
        </h1>
        <p>{t('ProductsAdmin.Description')}</p>
      </div>
      <div className="mt-8 flex justify-between items-center">
        <Button variant="tertiary" onClick={() => setIsModalOpen(true)}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {t('ProductsAdmin.AddNewProduct')}
        </Button>
        <div className="relative">
          <input
            type="text"
            placeholder={t('ProductsAdmin.SearchPlaceholder')}
            onChange={handleSearch}
            className="pl-10 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="mt-8">
          <table className="w-full table-fixed border-collapse">
            <thead className="bg-slate-300 dark:bg-stone-800">
              <tr>
                <th className="w-1/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('ProductsAdmin.ID')}
                </th>
                <th className="w-2/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('ProductsAdmin.Name')}
                </th>
                <th className="w-3/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('ProductsAdmin.DescriptionColumn')}
                </th>
                <th className="w-1/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('ProductsAdmin.Price')}
                </th>
                <th className="w-1/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('ProductsAdmin.DiscountPercent')}
                </th>
                <th className="w-2/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t('ProductsAdmin.Actions')}
                </th>
              </tr>
            </thead>
            <tbody className="text-stone-900 dark:text-zinc-300 bg-slate-200 dark:bg-stone-700">
              {displayedProducts.map(product => (
                <tr key={product.id} className="hover:bg-slate-300 dark:hover:bg-stone-600">
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    {product.id}
                  </td>
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    {product.product_name}
                  </td>
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    {product.description}
                  </td>
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    ${product.price}
                  </td>
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    {product.discount_percent}%
                  </td>
                  <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                    <div className="flex flex-row w-full gap-4">
                      <Button
                        className="w-1/2"
                        variant="tertiary"
                        onClick={() => handleEdit(product)}
                        data-tooltip-content={t('Edit Product')}
                        data-tooltip-id="tooltip"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Button>
                      <Button
                        variant="cancel"
                        className="w-1/2"
                        onClick={() => handleDelete(product)}
                        data-tooltip-content={t('Delete Product')}
                        data-tooltip-id="tooltip"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        title={selectedProduct ? t('ProductsAdmin.EditProduct') : t('ProductsAdmin.AddNewProduct')}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedProduct(null);
          setSelectedIngredients([]);
          setSizes([{ size_name: '', size_price: '' }]);
        }}
        className="max-w-[650px]"
      >
        <form onSubmit={handleSubmit} className="space-y-4 w-[600px] h-auto">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="product_name" className="block text-lg font-medium mb-1">
                {t('ProductsAdmin.ProductName')}
              </label>
              <input
                type="text"
                id="product_name"
                name="product_name"
                defaultValue={selectedProduct?.product_name}
                className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full"
                required
              />
            </div>
            <div>
              <label htmlFor="category_id" className="block text-lg font-medium mb-1">
                {t('ProductsAdmin.Category')}
              </label>
              <select
                id="category_id"
                name="category_id"
                defaultValue={selectedProduct?.category_id}
                className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full"
                required
              >
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="description" className="block text-lg font-medium mb-1">
              {t('Description')}
            </label>
            <textarea
              id="description"
              name="description"
              defaultValue={selectedProduct?.description}
              className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full resize"
              rows={3}
              required
            />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div>
              <label htmlFor="price" className="block text-lg font-medium mb-1">
                {t('ProductsAdmin.Price')}
              </label>
              <input
                type="number"
                id="price"
                name="price"
                defaultValue={selectedProduct?.price}
                step="0.1"
                className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                required
              />
            </div>
            <div>
              <label htmlFor="discount_percent" className="block text-lg font-medium mb-1">
                {t('ProductsAdmin.DiscountPercent')}
              </label>
              <input
                type="number"
                id="discount_percent"
                name="discount_percent"
                defaultValue={selectedProduct?.discount_percent}
                step="0.01"
                className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                required
              />
            </div>
            <div>
              <label htmlFor="active" className="block text-lg font-medium mb-1">
                {t('ProductsAdmin.Active')}
              </label>
              <input
                type="checkbox"
                id="active"
                name="active"
                defaultChecked={selectedProduct ? selectedProduct.active === 1 : true}
                className="form-checkbox h-5 w-5 text-blue-600 mt-2"
              />
            </div>
          </div>
          <div>
            <label htmlFor="image_url" className="block text-lg font-medium mb-1">
              {t('ProductsAdmin.ImageURL')}
            </label>
            <input
              type="text"
              id="image_url"
              name="image_url"
              defaultValue={selectedProduct?.image_url}
              className="pl-3 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-full"
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-lg font-medium mb-1">
                {t('ProductsAdmin.Ingredients')}
              </label>
              <div className="max-h-40 overflow-y-auto">
                {ingredients.map(ingredient => (
                  <div key={ingredient.id} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      id={`ingredient-${ingredient.id}`}
                      checked={selectedIngredients.includes(ingredient.id)}
                      onChange={() => handleIngredientChange(ingredient.id)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <label htmlFor={`ingredient-${ingredient.id}`} className="ml-2">
                      {t(ingredient.ingredient_name)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium mb-1">{t('ProductsAdmin.Sizes')}</label>
              {sizes.map((size, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="text"
                    placeholder={t('ProductsAdmin.SizeName')}
                    value={size.size_name}
                    onChange={e => handleSizeChange(index, 'size_name', e.target.value)}
                    className="pl-3 pr-4 py-2 text-sm border border-gray-400 dark:border-0 md:text-md rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-1/2 mr-2"
                  />
                  <input
                    type="number"
                    placeholder={t('ProductsAdmin.Price')}
                    value={size.size_price}
                    min="0"
                    step="0.1"
                    onChange={e => handleSizeChange(index, 'size_price', e.target.value)}
                    className="pl-3 pr-4 py-2 text-sm border border-gray-400 dark:border-0 md:text-md rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500 w-1/3 mr-2"
                  />
                  <Button type="button" variant="cancel" onClick={() => removeSize(index)}>
                    {t('ProductsAdmin.RemoveSize')}
                  </Button>
                </div>
              ))}
              <Button type="button" variant="tertiary" onClick={addSize}>
                {t('ProductsAdmin.AddSize')}
              </Button>
            </div>
          </div>
          <div className="justify-end flex">
            <Button type="submit" variant="tertiary">
              {selectedProduct ? t('ProductsAdmin.UpdateProduct') : t('ProductsAdmin.AddProduct')}
            </Button>
          </div>
        </form>
      </Modal>
      <DeleteWarning
        modalOpen={isDeleteModalOpen}
        setModalOpen={setIsDeleteModalOpen}
        onConfirm={confirmDelete}
        description={t('ProductsAdmin.DeleteConfirmation')}
      />
    </div>
  );
};

export default Products;
