import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressBook,
  faEye,
  faLock,
  faMapLocationDot,
  faLanguage,
  faUserXmark
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import LogoutIcon from '../../common/icons/logout';
import Language from '../../common/Language';

interface SelectionPanelProps {
  selection: string;
  handleSelection: (selection: string) => void;
}

const SelectionPanel: React.FC<SelectionPanelProps> = ({ selection, handleSelection }) => {
  const { t } = useTranslation();

  const selections = [
    {
      name: 'User Informations',
      icon: faAddressBook
    },
    {
      name: 'Password Update',
      icon: faLock
    },
    {
      name: 'Appearance',
      icon: faEye
    },
    {
      name: 'Registered Addresses',
      icon: faMapLocationDot
    },
    {
      name: 'Delete Account',
      icon: faUserXmark,
      variant: 'danger'
    }
  ];

  return (
    <div className="w-full">
      <div className="flex flex-col justify-start min-w-[300px] w-full h-auto text-stone-900 dark:text-white bg-slate-200 dark:bg-stone-800 rounded-md mt-12 font-semibold">
        <ul className="cursor-pointer">
          {selections.map(item => (
            <li
              key={item.name}
              className={clsx(
                'h-20 flex flex-row justify-start items-center pl-2',
                selection === item.name &&
                  'bg-slate-300 dark:bg-orange-900 dark:bg-opacity-50 rounded-t-md'
              )}
              onClick={() => handleSelection(item.name)}
            >
              <FontAwesomeIcon
                icon={item.icon}
                className={clsx(
                  'w-7 mx-2 mb-5',
                  selection === item.name && 'text-orange-500 dark:text-orange-400'
                )}
              />
              <ul>
                <li
                  className={clsx(
                    selection === item.name && 'text-orange-500 dark:text-orange-400'
                  )}
                >
                  {t(item.name)}
                </li>
                <li className="text-[14px] font-thin text-stone-500 dark:text-zinc-300">
                  {t(`${item.name} Alt`)}
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-auto mb-3 flex flex-row gap-2 justify-end items-center w-full h-14 md:hidden">
        <div className="flex-1">
          <Language direction="row" />
        </div>
        <Button
          variant="cancel"
          className="flex flex-row w-full h-auto text-[16px] justify-center items-center"
        >
          <LogoutIcon width="20px" height="20px" color="#9a3412" />
          <p className="ml-2">{t('Logout')}</p>
        </Button>
      </div>
    </div>
  );
};

export default SelectionPanel;
