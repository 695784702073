import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import Button from '../../common/Button';
import { faPlus, faPercent } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../services/api';
import Spinner from '../../common/Spinner';
import debounce from 'lodash/debounce';
import { CategoryData, ProductProps } from '../../../types/homepage';
import { motion, AnimatePresence } from 'framer-motion';
import { useWebSettings } from '../../../context/WebSettingsContext';
import { useTranslation } from 'react-i18next';

interface ExtendedProductProps extends ProductProps {
  onProductSelect: (productId: number) => void;
}

const Products: React.FC<ExtendedProductProps> = ({
  selectedCategory,
  searchTerm,
  categories,
  onProductSelect
}) => {
  const [productData, setProductData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [itemsToShow, setItemsToShow] = useState<number>(8);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const initialLoadDone = useRef(false);
  const [hasMoreItems, setHasMoreItems] = useState<boolean>(true);
  const { t } = useTranslation();
  const { openData } = useWebSettings();

  const truncateDescription = (description: string, maxLength = 60) => {
    return description.length > maxLength
      ? `${description.substring(0, maxLength)}...`
      : description;
  };

  const getEffectiveDiscount = (product: any) => {
    const productDiscount = parseFloat(product.discount_percent);
    const category = categories.find(c => c.id.toString() === product.category_id.toString());
    const categoryDiscount = category ? parseFloat(category.discount_percent) : 0;
    return Math.max(productDiscount, categoryDiscount);
  };

  const calculateDiscountedPrice = (price: string, discountPercent: number) => {
    const basePrice = parseFloat(price);
    return (basePrice * (1 - discountPercent / 100)).toFixed(2);
  };

  const shuffleArray = (array: any[]) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Fetch products once
  useEffect(() => {
    const fetchAllProducts = async () => {
      if (initialLoadDone.current) return; // Prevent multiple fetches

      try {
        setLoading(true);
        const response = await api.get('/products/get-all-products/');
        const shuffledProducts = shuffleArray(response.data.data);
        setProductData(shuffledProducts);
        initialLoadDone.current = true;
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch product data:', err);
        setLoading(false);
      }
    };
    fetchAllProducts();
  }, []);

  // Filter and slice products in one step
  const visibleProducts = useMemo(() => {
    const filtered = productData.filter(
      product =>
        product.active &&
        (selectedCategory === 'all' || product.category_id.toString() === selectedCategory) &&
        (product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    setHasMoreItems(filtered.length > itemsToShow);

    return filtered.slice(0, itemsToShow);
  }, [selectedCategory, searchTerm, productData, itemsToShow]);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.innerHeight + window.pageYOffset;
    const threshold = document.documentElement.offsetHeight - 500; // Increased threshold

    if (scrollPosition >= threshold && !isLoadingMore && hasMoreItems) {
      setIsLoadingMore(true);
      setTimeout(() => {
        setItemsToShow(prevItemsToShow => prevItemsToShow + 8); // Increased items per load
        setIsLoadingMore(false);
      }, 500);
    }
  }, [isLoadingMore, hasMoreItems]);

  const debouncedHandleScroll = useMemo(() => debounce(handleScroll, 200), [handleScroll]);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
      debouncedHandleScroll.cancel();
    };
  }, [debouncedHandleScroll]);

  if (loading) {
    return (
      <div className="mt-20">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className="mt-8 overflow-x-hidden overflow-y-auto w-screen md:w-full"
      >
        <motion.h3
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}
          className="text-stone-800 dark:text-white text-2xl font-semibold"
        >
          {t('Choose your dishes')}
        </motion.h3>

        <motion.p
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}
          className="w-[90%] text-red-600"
        >
          {!openData?.open ? (
            <>
              {t('Store is closed now. You cannot order. Come back later. It will open at')}{' '}
              <span className="text-amber-950 dark:text-amber-700">{openData?.open_time}</span>
            </>
          ) : (
            <>
              <span className="!text-orange-600 dark:!text-orange-400">
                *
                {t('Images are representative. Please take this into consideration when ordering.')}
              </span>
            </>
          )}
        </motion.p>
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-6 w-[94%] md:w-full">
          <AnimatePresence>
            {visibleProducts.map(
              (product, index) =>
                product.active && (
                  <motion.div
                    key={product.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: index * 0.05 }}
                    className="p-3.5 bg-white dark:bg-stone-700 rounded-3xl shadow-lg border border-gray-100 dark:border-stone-700 shadow-gray-500/30 flex flex-col justify-between "
                  >
                    <div className="flex items-center justify-center relative">
                      <img
                        src={product.image_url}
                        alt={`${product.product_name} product`}
                        className="w-auto h-32  md:h-48 rounded-3xl cursor-pointer"
                        onClick={() => openData?.open && onProductSelect(product.id)}
                      />
                      {getEffectiveDiscount(product) > 0 && (
                        <div className="absolute top-0 right-0 bg-green-500 text-white text-sm font-bold px-2 py-1 rounded-full">
                          {getEffectiveDiscount(product)}%
                        </div>
                      )}
                    </div>
                    <h3
                      className="text-black dark:text-white  font-semibold text-md md:font-bold  md:text-xl mt-2 break-words cursor-pointer"
                      onClick={() => openData?.open && onProductSelect(product.id)}
                    >
                      {product.product_name}
                    </h3>
                    <p className="hidden md:block text-stone-400 text-lg font-thin">
                      {truncateDescription(product.description, 30)}
                    </p>

                    <div className="flex justify-between items-center mt-2">
                      <div>
                        {getEffectiveDiscount(product) > 0 ? (
                          <>
                            <p className="text-gray-500 dark:text-gray-400 line-through text-sm">
                              {parseFloat(product.price).toFixed(2)}€
                            </p>
                            <p className="text-black dark:text-white font-bold text-xl">
                              {calculateDiscountedPrice(
                                product.price,
                                getEffectiveDiscount(product)
                              )}
                              €
                            </p>
                          </>
                        ) : (
                          <p className="text-black dark:text-white font-bold text-xl">
                            {parseFloat(product.price).toFixed(2)}€
                          </p>
                        )}
                      </div>
                      <Button
                        variant="tertiary"
                        onClick={() => onProductSelect(product.id)}
                        disabled={!openData?.open}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </div>
                  </motion.div>
                )
            )}
          </AnimatePresence>
        </div>

        {hasMoreItems && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            className="flex flex-col items-center justify-center gap-2 pb-6 text-stone-600 dark:text-stone-300"
          >
            <div className="animate-bounce">↓</div>
            <p className="text-sm text-center">{t('Scroll down to see more dishes')}</p>
          </motion.div>
        )}

        {isLoadingMore && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex justify-center my-4"
          >
            <Spinner />
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default Products;
